<template>
  <div class="traceroute-result">
    <h2>Résultats Traceroute pour {{ this.ipAddress }}</h2>
    <div v-if="loading" class="loading">Chargement<span v-html="dots"></span></div>
    <table v-else-if="resultData">
      <thead>
        <tr>
          <th>Hop</th>
          <th>Probe IP</th>
          <th>Name</th>
          <th>ASN</th>
          <th>RTTs</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="hop in resultData.traceroute_result.output.hops" :key="hop.hop">
          <td>{{ hop.hop }}</td>
          <td v-if="hop.probes && hop.probes.length > 0">{{ hop.probes[0].ip }}</td>
          <td v-if="hop.probes && hop.probes.length > 0">{{ hop.probes[0].name }}</td>
          <td v-if="hop.probes && hop.probes.length > 0">{{ hop.probes[0].asn }}</td>
          <td>
            <p v-if="hop.probes && hop.probes.length > 0">{{ hop.probes.map(probe => probe.rtt).join(", ") }}</p>
            <p v-else>* * *</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TracerouteResult',
  data() {
    return {
      resultData: null,
      loading: false,
      dots: '',
      ipAddress: this.$route.params.ipAddress
    }
  },
  async created() {
    this.loading = true;
    let dotsInterval = setInterval(() => {
      this.dots.length < 6 ? this.dots += '.' : this.dots = ''
    }, 300);

    try {
      const response = await axios.get(`https://apollo-213225239-108.titandc.io/api/traceroute?ip_address=${this.ipAddress}`)
      this.resultData = response.data
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
      clearInterval(dotsInterval);
    }
  }
}
</script>

<style scoped>
.traceroute-result {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
}

.traceroute-result table {
  width: 100%;
  border-collapse: collapse;
}

.traceroute-result th, .traceroute-result td {
  border: 0px solid #ddd;
  padding: 8px;
}

.traceroute-result th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}

.loading {
  margin: 20px 0;
}

.loading span {
  margin-left: 5px;
  opacity: 0;
  animation: loadingDots 1s infinite;
}

@keyframes loadingDots {
  0% { opacity: 0; }
  33% { opacity: 1; }
  66% { opacity: 0; }
  100% { opacity: 0; }
}
</style>

