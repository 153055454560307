import { createRouter, createWebHistory } from 'vue-router'
import PingResult from '@/components/PingResult.vue'
import TracerouteResult from '@/components/TracerouteResult.vue'

const routes = [
  {
    path: '/ping/:ipAddress',
    name: 'Ping',
    component: PingResult
  },
  {
    path: '/traceroute/:ipAddress',
    name: 'Traceroute',
    component: TracerouteResult
  }
  // Vous pouvez ajouter plus de routes ici
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

