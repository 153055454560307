<template>
  <div class="ping-result">
    <h1>Ping Result</h1>

    <p v-if="pingResult && pingResult.ping_result && pingResult.ping_result.error">
      Error: {{ pingResult.ping_result.error }}
    </p>

    <table v-else-if="pingResult && pingResult.ping_result && pingResult.ping_result.output && pingResult.ping_result.output.responses">
      <thead>
        <tr>
          <th>Type</th>
          <th>Bytes</th>
          <th>Response IP</th>
          <th>ICMP Seq</th>
          <th>TTL</th>
          <th>Time (ms)</th>
          <th>Duplicate</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="response in pingResult.ping_result.output.responses" :key="response.icmp_seq">
          <td>{{ response.type }}</td>
          <td>{{ response.bytes }}</td>
          <td>{{ response.response_ip }}</td>
          <td>{{ response.icmp_seq }}</td>
          <td>{{ response.ttl }}</td>
          <td>{{ response.time_ms }}</td>
          <td>{{ response.duplicate }}</td>
        </tr>
      </tbody>
    </table>

    <p v-else>Loading...</p>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      pingResult: null
    }
  },
  async created() {
    try {
      const response = await axios.get(`https://apollo-213225239-108.titandc.io/api/ping?ip_address=${this.$route.params.ipAddress}`)
      this.pingResult = response.data
    } catch (error) {
      console.error(error)
    }
  }
}
</script>
<style scoped>
.ping-result {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
}

.ping-result table {
  width: 100%;
  border-collapse: collapse;
}

.ping-result th, .ping-result td {
  border: 1px solid #ddd;
  padding: 8px;
}

.ping-result th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}
</style>

